.attributes__list{
    list-style: none;
    padding: 0;
    margin:0;
}
@media all and (max-width: 768px) {
	.attributes__list {
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    align-items: center; 
	}
}
.attributes__item {
    margin-bottom: 10px;
}
.ant-typography.attributes__title{
    font-size: 15px;
    font-weight: 600px;
    margin-bottom: 20px;
}

/*buttons*/
.ant-btn.button--A{
    border: 1px solid #5C5CEA;
    color: #5C5CEA;
    background-color: white;
}
.ant-btn.button--B{
    border: 1px solid #A8BC3B;
    color: #A8BC3B;
    background-color: white;
}
.ant-btn.button--C{
    border: 1px solid #CA1551;
    color: #CA1551;
    background-color: white;
}
.ant-btn.button--D{
    border: 1px solid #FDB833;
    color: #FDB833;
    background-color: white;
}

/*button selected*/
.ant-btn.button--A.selected.ant-btn-primary {
    background: #5C5CEA;
    color: white;
    font-weight: bold;
    border: none;
}
.ant-btn.button--B.selected.ant-btn-primary {
    background: #A8BC3B;
    color: white;
    font-weight: bold;
    border: none;
}
.ant-btn.button--C.selected.ant-btn-primary {
    background: #CA1551;
    color: white;
    font-weight: bold;
    border: none;
}
.ant-btn.button--D.selected.ant-btn-primary {
    background: #FDB833;
    color: white;
    font-weight: bold;
    border: none;
}
